<template>
  <div>
    <iframe
      :key="updateKey"
      height="100%"
      width="100%"
      :src="getFilePath"
    />
  </div>
</template>

<script>
  export default {
    name: 'PDFJSViewer',
    props: {
      path: {
        type: String,
        default: '',
      },
    },
    computed: {
      getFilePath () {
        switch (process.env.NODE_ENV) {
          case 'development':
            return 'lib/pdfjs-2.6.347-dist/web/viewer.html' + '?file=' + this.path
          case 'production':
            return 'lib/pdfjs-2.6.347-dist/web/viewer.html?file='
          default:
            return this.path
        }
      },
      updateKey () {
        return this.path
      },
    },
  }
</script>
<style scoped>
div {
  width: 100%;
  height: 79vh;
  min-width: 400px;
}
</style>
