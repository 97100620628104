import Vue from 'vue'
import Keycloak from 'keycloak-js'

const initOptions = {
  url: process.env.VUE_APP_KEYCLOAK_URL,
  realm: process.env.VUE_APP_KEYCLOAK_REALM,
  clientId: process.env.VUE_APP_CLIENT_ID,
}

const _keycloak = Keycloak(initOptions)

const Plugin = {
  install: (Vue) => {
    Vue.$keycloak = _keycloak
  },
}

Plugin.install = (Vue) => {
  Vue.$keycloak = _keycloak
  Object.defineProperties(Vue.prototype, {
    $keycloak: {
      get () {
        return _keycloak
      },
    },
  })
}

Vue.use(Plugin)

export default Plugin
