import 'file-saver/dist/FileSaver.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-table/dist/bootstrap-table.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'flag-icon-css/css/flag-icons.css'

import './jquery'
import Vue from 'vue'
import 'bootstrap'
import 'bootstrap-table/dist/bootstrap-table'
import 'bootstrap-table/dist/extensions/cookie/bootstrap-table-cookie.js'
import 'bootstrap-table/dist/extensions/export/bootstrap-table-export.js'
import './table-export'

import BootstrapTable from 'bootstrap-table/dist/bootstrap-table-vue.js'

Vue.component('BootstrapTable', BootstrapTable)
