// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import './plugins/keycloak'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VueTyperPlugin from 'vue-typer'
// import updateToken from './middlewares/update-token'

Vue.config.productionTip = false
Vue.use(VueTyperPlugin)

// calling keycloak API using our plugin
Vue.$keycloak
  .init({
    onLoad: 'login-required',
    checkLoginIframeInterval: 600, // check status after every 5 mins
  })
  .then((auth) => {
    if (auth) {
      // console.log(Vue.$keycloak)
      new Vue({
        router,
        vuetify,
        i18n,
        render: (h) => h(App, { props: { keycloak: Vue.$keycloak } }),
      }).$mount('#app')

      // window.onfocus = () => {
      //   updateToken()
      // }
    }
  })

// new Vue({
//   router,
//   store,
//   vuetify,
//   i18n,
//   render: (h) => h(App),
// }).$mount('#app')
