<template>
  <router-view />
</template>

<script>
  import './plugins/table.js'
  export default {
    name: 'App',
  }
</script>
